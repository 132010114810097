import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { ResourceActionFailed } from '@movecloser/front-core';
import { Inject } from '@plugin/inversify';
import { AbstractModal } from '@component/Modal';
import { Screen } from '@component/Screen';
import { UserRepositoryType } from '../../../contracts';
let SetNicknameModal = class SetNicknameModal extends AbstractModal {
    constructor() {
        super(...arguments);
        this.errors = {};
        this.isLoading = false;
        this.nickname = '';
    }
    async submit() {
        this.isLoading = true;
        try {
            await this.userRepository.update({
                nickname: this.nickname
            });
            await this.userRepository.get();
            window.location.reload();
        }
        catch (e) {
            if (e instanceof ResourceActionFailed) {
                this.errors = e.payload;
            }
        }
        finally {
            this.isLoading = false;
        }
    }
};
__decorate([
    Inject(UserRepositoryType)
], SetNicknameModal.prototype, "userRepository", void 0);
SetNicknameModal = __decorate([
    Component({
        name: 'SetNicknameModal',
        components: { Screen }
    })
], SetNicknameModal);
export { SetNicknameModal };
export default SetNicknameModal;
